import React, { useEffect, useState } from "react";
import {
  FaUsers,
  FaChalkboardUser,
  FaMoneyBillTransfer,
} from "react-icons/fa6";
import "./TeacherDashboard.css";
import Chart from "../../chart/Chart";
import Calender from "../../calender/Calender";
import { Card, Row, Col, message } from "antd";
import CustomCard from "../../card/CustomCard";
import NoticeBoard from "../../notice-board/NoticeBoard";
import useFetch from "../../../hooks/useFetch";

function TeacherDashboard() {
  // State for summary data, loading, and error
  const [summaryData, setSummaryData] = useState(null);
  const [respData, setRespData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch hook for dashboard data
  const { get: fetchDash, reset } = useFetch("/api/dashboard/admin-dashboard", {
    setLoading,
    setError,
    setData: setRespData,
  });

  // Dashboard icons and styling
  const dashIcons = [
    {
      icon: <FaUsers />,
      background: "linear-gradient(135deg, #34b3a0, #00796b)", // Teal to deep green
      label: "Total Students",
    },
    {
      icon: <FaUsers />,
      background: "linear-gradient(135deg, #FF6F61, #D84315)", // Coral to deep orange
      label: "Male Students",
    },
    {
      icon: <FaUsers />,
      background: "linear-gradient(135deg, #4CAF50, #388E3C)", // Green gradient
      label: "Female Students",
    },
    {
      icon: <FaChalkboardUser />,
      background: "linear-gradient(135deg, #1E90FF, #0047AB)", // Bright blue to navy blue
      label: "Total Teachers",
    },
  ];

  // Fetch dashboard data on component mount
  useEffect(() => {
    fetchDash();
  }, []);

  useEffect(() => {
    if (respData?.data) setSummaryData(respData.data);
  }, [respData]);

  // Display error message if any
  useEffect(() => {
    if (error) {
      message.error("Failed to load dashboard data.");
    }
    reset();
  }, [error]);

  // Prepare data for the chart
  const chartData = summaryData
    ? {
        labels: ["Male Students", "Female Students", "Teachers"],
        datasets: [
          {
            data: [
              summaryData.students?.maleCount || 0,
              summaryData.students?.femaleCount || 0,
              summaryData.teachers?.count || 0,
            ],
            backgroundColor: ["#FF6F61", "#4CAF50", "#1E90FF"],
            hoverBackgroundColor: ["#FF8A75", "#66BB6A", "#42A5F5"],
          },
        ],
      }
    : null;

  console.log(chartData);

  return (
    <div id="DashboardMain">
      <section className="main-content">
        <ul>
          <li>
            <a href="btn">Home</a>
          </li>
        </ul>

        <Row gutter={[16, 8]}>
          {summaryData && (
            <>
              <Col xs={24} sm={12} lg={6} style={{ marginBottom: 10 }}>
                <Card
                  className="custom-card"
                  style={{ background: dashIcons[0].background }}
                  title={dashIcons[0].label}
                  bordered={false}
                  hoverable
                  loading={loading}
                >
                  <CustomCard
                    amount={summaryData.students?.amount || 0}
                    icon={dashIcons[0].icon}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} lg={6} style={{ marginBottom: 10 }}>
                <Card
                  className="custom-card"
                  style={{ background: dashIcons[1].background }}
                  title={dashIcons[1].label}
                  bordered={false}
                  hoverable
                  loading={loading}
                >
                  <CustomCard
                    amount={summaryData.students?.maleCount || 0}
                    icon={dashIcons[1].icon}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} lg={6} style={{ marginBottom: 10 }}>
                <Card
                  className="custom-card"
                  style={{ background: dashIcons[2].background }}
                  title={dashIcons[2].label}
                  bordered={false}
                  hoverable
                  loading={loading}
                >
                  <CustomCard
                    amount={summaryData.students?.femaleCount || 0}
                    icon={dashIcons[2].icon}
                  />
                </Card>
              </Col>
              <Col xs={24} sm={12} lg={6} style={{ marginBottom: 10 }}>
                <Card
                  className="custom-card"
                  style={{ background: dashIcons[3].background }}
                  title={dashIcons[3].label}
                  bordered={false}
                  hoverable
                  loading={loading}
                >
                  <CustomCard
                    amount={summaryData.teachers?.count || 0}
                    icon={dashIcons[3].icon}
                  />
                </Card>
              </Col>
            </>
          )}
        </Row>

        <Row style={{ width: "100%" }} gutter={[16, 16]}>
          <Col sm={24} lg={12}>
            {chartData && <Chart chartData={chartData} />}
          </Col>
          <Col sm={24} lg={12}>
            <Calender />
          </Col>
          <Col sm={24} lg={12}>
            <NoticeBoard />
          </Col>
        </Row>
      </section>
    </div>
  );
}

export default TeacherDashboard;
