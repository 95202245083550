import React, { useState, useEffect } from "react";
import {
  message,
  Table,
  Select,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Input,
  Button,
  DatePicker,
  Popconfirm,
} from "antd";

import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import usePost from "../../../hooks/usePost";

const generateColumns = (handleShowAttendance, handleShowBehavior) => [
  { title: "Roll", key: "roll", dataIndex: "roll", editable: false },
  { title: "Name", key: "name", dataIndex: "fullName", editable: false },
  {
    title: "Attendance",
    key: "attendance",
    render: (text, record) => (
      <span
        onClick={() => handleShowAttendance(record)}
        style={{ cursor: "pointer", color: "blue" }}
      >
        {record.attendance?.[0]?.status || "No data"}
      </span>
    ),
  },
  {
    title: "Behavior",
    key: "behavior",
    render: (text, record) => (
      <span
        onClick={() => handleShowBehavior(record)}
        style={{ cursor: "pointer", color: "blue" }}
      >
        {record.behavior?.[0]?.rank || "No data"}
      </span>
    ),
  },
];

const StudentAttendanceAndBehavior = () => {
  const [formAttendance] = Form.useForm();
  const [formBehavior] = Form.useForm();

  const [dataSource, setDataSource] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);

  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isShowAttendanceModalOpen, setIsShowAttendanceModalOpen] =
    useState(false);
  const [isShowBehaviorModalOpen, setIsShowBehaviorModalOpen] = useState(false);

  const [isAttendanceModalOpen, setIsAttendanceModalOpen] = useState(false);
  const [isBehaviorModalOpen, setIsBehaviorModalOpen] = useState(false);

  const [isListChanged, setIsListChanged] = useState(false);
  const [currentUnchangedValue, setCurrentUnchangedValue] = useState(undefined);

  const { terms, fetchTerms } = useFetchTerms();
  const [currentTerm, setCurrentTerm] = useState(null);
  const [selectedTask, setSelectedTask] = useState("");

  const [myTasks, setMyTasks] = useState([]);
  const [myTasksLoading, setMyTasksLoading] = useState(false);
  const [myTasksError, setMyTasksError] = useState(null);

  const [attendanceLoading, setAttendanceLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState(null);
  const [attendanceError, setAttendanceError] = useState(null);

  const [behaviorLoading, setBehaviorLoading] = useState(false);
  const [behaviorData, setBehaviorData] = useState(null);
  const [behaviorError, setBehaviorError] = useState(null);

  const [
    buildAttendanceAndBehaviorLoading,
    setBuildAttendanceAndBehaviorLoading,
  ] = useState(false);
  const [buildAttendanceAndBehaviorError, setBuildAttendanceAndBehaviorError] =
    useState(null);
  const [buildAttendanceAndBehaviorRes, setBuildAttendanceAndBehaviorRes] =
    useState(null);

  const [selectedAttendanceData, setSelectedAttendanceData] = useState([]);
  const [selectedBehaviorData, setSelectedBehaviorData] = useState([]);

  const { post: updateAttendance } = usePost(
    "/api/attendance-behavior/update-attendance",
    {
      setLoading: setAttendanceLoading,
      setData: setAttendanceData,
      setError: setAttendanceError,
    }
  );

  const { post: updateBehavior } = usePost(
    "/api/attendance-behavior/update-behavior",
    {
      setLoading: setBehaviorLoading,
      setData: setBehaviorData,
      setError: setBehaviorError,
    }
  );

  const { get: fetchData } = useFetch("/api/attendance-behavior/data", {
    setLoading: setDataLoading,
    setData: setDataSource,
    setError: setDataError,
  });

  const { get: fetchTasks } = useFetch("/api/attendance-behavior/tasks", {
    setLoading: setMyTasksLoading,
    setData: setMyTasks,
    setError: setMyTasksError,
  });

  const { post: buildAttendanceAndBehavior } = usePost(
    "/api/attendance-behavior/build",
    {
      setLoading: setBuildAttendanceAndBehaviorLoading,
      setError: setBuildAttendanceAndBehaviorError,
      setData: setBuildAttendanceAndBehaviorRes,
    }
  );

  useEffect(() => {
    fetchTasks();
    fetchTerms();
  }, []);

  useEffect(() => {
    if (terms) setCurrentTerm(terms.find((term) => term.status === "current"));
  }, [terms]);

  useEffect(() => {
    if (selectedTask?.grade && currentTerm?.name) {
      getSelectedData();
    }
  }, [selectedTask]);

  const getSelectedData = () => {
    fetchData({
      grade: selectedTask.grade,
      section: selectedTask.section,
      termCode: currentTerm.code,
    });
  };

  const handleTaskChange = (val) => {
    setSelectedTask(myTasks.find((task) => task.id === val));
  };

  const handleEditAttendance = (record) => {
    setSelectedRowData(record);
    setIsAttendanceModalOpen(true);
    setIsShowAttendanceModalOpen(false);
  };

  const handleEditBehavior = (record) => {
    setSelectedRowData(record);
    setIsBehaviorModalOpen(true);
    setIsShowBehaviorModalOpen(false);
  };

  const handleShowAttendance = (record) => {
    if (!record.attendance)
      return message.error("No attendance data found for this student");
    setSelectedAttendanceData(record.attendance);
    setSelectedRowData(record);
    setIsShowAttendanceModalOpen(true);
  };

  const handleShowBehavior = (record) => {
    if (!record.behavior)
      return message.error("No behavior data found for this student");
    setSelectedBehaviorData(record.behavior);
    setSelectedRowData(record);
    setIsShowBehaviorModalOpen(true);
  };

  const handleAttendanceModalClose = () => {
    getSelectedData();
    formAttendance.resetFields();
    setIsAttendanceModalOpen(false);
  };

  const handleBehaviorModalClose = () => {
    getSelectedData();
    formBehavior.resetFields();
    setIsBehaviorModalOpen(false);
  };

  const handleSaveAttendance = async () => {
    try {
      const values = await formAttendance.validateFields();

      const newEntry = {
        date: values.date.format("YYYY-MM-DD"),
        status: values.status,
      };

      const updatedAttendanceData = selectedRowData.attendance.map((item) =>
        item.date === newEntry.date ? newEntry : item
      );

      if (!updatedAttendanceData.some((item) => item.date === newEntry.date)) {
        updatedAttendanceData.push(newEntry);
      }

      await updateAttendance({
        studentId: selectedRowData.studentId,
        termCode: currentTerm.code,
        attendanceData: updatedAttendanceData,
        setBy: "Admin",
      });

      getSelectedData();
    } catch (error) {
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  const handleSaveBehavior = async () => {
    try {
      const values = await formBehavior.validateFields();

      const newEntry = {
        date: values.date.format("YYYY-MM-DD"),
        comment: values.comment || "",
        rank: values.rank,
      };

      const updatedBehaviorData = selectedRowData.behavior.map((item) =>
        item.date === newEntry.date ? newEntry : item
      );

      if (!updatedBehaviorData.some((item) => item.date === newEntry.date)) {
        updatedBehaviorData.push(newEntry);
      }

      await updateBehavior({
        studentId: selectedRowData.studentId,
        termCode: currentTerm.code,
        behaviorData: updatedBehaviorData,
        setBy: "Admin",
      });

      getSelectedData();
    } catch (error) {
      if (error?.errorFields) {
        error?.errorFields.forEach((element, index) => {
          if (index < 4) message.error(element.errors[0]);
        });
      }
    }
  };

  useEffect(() => {
    if (attendanceData?.message) {
      message.success(
        attendanceData.message || "Attendance updated successfully!"
      );
    }
  }, [attendanceData]);

  useEffect(() => {
    if (attendanceError) {
      message.error(
        attendanceError.message || "Failed to update attendance data."
      );
    }
  }, [attendanceError]);

  useEffect(() => {
    if (behaviorData?.message) {
      message.success(behaviorData.message || "Behavior updated successfully!");
    }
  }, [behaviorData]);

  useEffect(() => {
    if (behaviorError?.message) {
      message.error(behaviorError.message || "Failed to update behavior data.");
    }
  }, [behaviorError]);

  useEffect(() => {
    if (buildAttendanceAndBehaviorRes?.status)
      message[buildAttendanceAndBehaviorRes?.status || "success"](
        buildAttendanceAndBehaviorRes?.message || "success"
      );
  }, [buildAttendanceAndBehaviorRes]);

  useEffect(() => {
    if (buildAttendanceAndBehaviorError?.status)
      message[buildAttendanceAndBehaviorError?.status || "error"](
        buildAttendanceAndBehaviorError?.message || "error"
      );
  }, [buildAttendanceAndBehaviorError]);

  const saveOriginalValue = (record, key) => {
    const copiedRecord = structuredClone(record);
    setCurrentUnchangedValue({ copiedRecord, key });
  };

  const handlePrev = (record) => {
    const currentIndex = dataSource.findIndex(
      (item) => item.studentId === record.studentId
    );
    console.log("Prev:", currentIndex, dataSource.length);
    if (currentIndex === -1) return message.warning("No previous student");

    if (currentIndex > 0) {
      const prevRecord = dataSource[currentIndex - 1];
      setSelectedRowData(prevRecord);
      saveOriginalValue(prevRecord);
    } else {
      message.warning("No previous student");
      return null;
    }
  };

  const handleNext = (record) => {
    if (formAttendance.getFieldValue("date") && isAttendanceModalOpen)
      handleSaveAttendance();
    if (formBehavior.getFieldValue("date") && isBehaviorModalOpen)
      handleSaveBehavior();
    const currentIndex = dataSource.findIndex(
      (item) => item.studentId === record.studentId
    );
    console.log("Next:", currentIndex, dataSource.length);
    if (currentIndex === -1) return message.warning("No next student");
    console.log(currentIndex, dataSource.length, record);

    if (currentIndex < dataSource.length - 1) {
      const nextRecord = dataSource[currentIndex + 1];
      setSelectedRowData(nextRecord);
      saveOriginalValue(nextRecord);
    } else {
      message.warning("No next student");
      return null;
    }
  };

  return (
    <Card title="Student Attendance & Behavior">
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Popconfirm
          title="This will take sometime"
          onConfirm={() => buildAttendanceAndBehavior({})}
        >
          <Button
            loading={buildAttendanceAndBehaviorLoading}
            type="primary"
            style={{ margin: "10px 0" }}
          >
            Build Attend and Behave
          </Button>
        </Popconfirm>
      </div>
      <Row gutter={[16, 8]} style={{ margin: "10px 0" }}>
        <Col span={8}>
          <Select
            showSearch
            placeholder="Select Task"
            optionFilterProp="children"
            onChange={handleTaskChange}
            loading={myTasksLoading}
            style={{ width: "100%" }}
          >
            {myTasks.map((task) => (
              <Select.Option key={task.id} value={task.id}>
                {`${task.grade} ${task.section}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>{currentTerm?.name}</Col>
      </Row>
      <Table
        bordered
        pagination={{ pageSize: 50 }}
        dataSource={dataSource}
        columns={generateColumns(handleShowAttendance, handleShowBehavior)}
        loading={dataLoading}
      />
      {/* Attendance Modal */}
      <Modal
        title={`Add Attendance`}
        open={isAttendanceModalOpen}
        onCancel={handleAttendanceModalClose}
        footer={[
          <div style={{ display: "flex" }}>
            <Button key="prev" onClick={() => handlePrev(selectedRowData)}>
              Prev
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              key="next"
              onClick={() => handleNext(selectedRowData)}
            >
              Next
            </Button>
          </div>,
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button key="cancel" onClick={handleAttendanceModalClose}>
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              key="save"
              type="primary"
              onClick={handleSaveAttendance}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <p>
          Roll: {selectedRowData?.roll} | ID: {selectedRowData?.studentId} |
          Name: {selectedRowData?.fullName}
        </p>
        <Form form={formAttendance} layout="vertical">
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Please select a date!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            rules={[{ required: true, message: "Please select a status!" }]}
            initialValue={"Absent"}
          >
            <Select>
              <Select.Option value="Absent">Absent</Select.Option>
              <Select.Option value="Late">Late</Select.Option>
              <Select.Option value="Present">Present</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {/* Behavior Modal */}
      <Modal
        title={`Add Behavior`}
        open={isBehaviorModalOpen}
        onCancel={handleBehaviorModalClose}
        footer={[
          <div style={{ display: "flex" }}>
            <Button
              key="prev"
              onClick={() => handlePrev(selectedRowData)}
              loading={behaviorLoading}
            >
              Prev
            </Button>
            <Button
              style={{ marginLeft: 10 }}
              key="next"
              onClick={() => handleNext(selectedRowData)}
              loading={behaviorLoading}
            >
              Next
            </Button>
          </div>,
          <div style={{ display: "flex", justifyContent: "end" }}>
            <Button
              style={{ marginLeft: 10 }}
              key="save"
              type="primary"
              onClick={handleSaveBehavior}
              loading={behaviorLoading}
            >
              Save
            </Button>
          </div>,
        ]}
      >
        <p>
          Roll: {selectedRowData?.roll} | ID: {selectedRowData?.studentId} |
          Name: {selectedRowData?.fullName}
        </p>
        <Form form={formBehavior} layout="vertical">
          <Form.Item
            name="date"
            label="Date"
            rules={[{ required: true, message: "Please select a date!" }]}
          >
            <DatePicker />
          </Form.Item>
          <Form.Item name="comment" label="Comment">
            <Input.TextArea rows={3} />
          </Form.Item>
          <Form.Item
            name="rank"
            label="Rank"
            rules={[{ required: true, message: "Please select a rank!" }]}
          >
            <Select>
              <Select.Option value="A">A</Select.Option>
              <Select.Option value="B">B</Select.Option>
              <Select.Option value="C">C</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      {/* Show Attendance Modal */}
      <Modal
        title="Student Attendance"
        open={isShowAttendanceModalOpen}
        onCancel={() => setIsShowAttendanceModalOpen(false)}
        footer={[
          <Button
            key="edit"
            type="primary"
            onClick={() => handleEditAttendance(selectedRowData)}
          >
            Add Attendance
          </Button>,
        ]}
      >
        <Table
          dataSource={selectedAttendanceData.map((item, index) => ({
            ...item,
            key: index,
            setBy: item.setBy || "N/A", // Provide a default value if setBy is undefined
          }))}
          columns={[
            { title: "Date", dataIndex: "date", key: "date" },
            { title: "Status", dataIndex: "status", key: "status" },
          ]}
        />
      </Modal>
      {/* Show Behavior Modal */}
      <Modal
        title="Student Behavior"
        open={isShowBehaviorModalOpen}
        onCancel={() => setIsShowBehaviorModalOpen(false)}
        footer={[
          <Button
            key="edit"
            type="primary"
            onClick={() => handleEditBehavior(selectedRowData)}
          >
            Add Behavior
          </Button>,
        ]}
      >
        <Table
          dataSource={selectedBehaviorData.map((item, index) => ({
            ...item,
            key: index,
            setBy: item.setBy || "N/A", // Provide a default value if setBy is undefined
          }))}
          columns={[
            { title: "Date", dataIndex: "date", key: "date" },
            { title: "Rank", dataIndex: "rank", key: "rank" },
            { title: "Comment", dataIndex: "comment", key: "comment" },
          ]}
        />
      </Modal>
    </Card>
  );
};

export default StudentAttendanceAndBehavior;
