const comments = {
  very_good: [
    "Excellent! Keep up the work. However, {name} can still improve in {re_subject_low}.",
    "I have truly enjoyed being {name}'s teacher and will miss {him_her} next year. Encourage {him_her} to work on {re_subject_low}.",
    "{name} is an asset to the class. Congratulations! However, {he_she} should focus on {re_subject_low}.",
    "{name} is an outstanding student. {he_she} can still improve in {re_subject_low}.",
    "{name} is a good model for other students. Encourage {him_her} to work on {re_subject_low}.",
    "{he_she} is a very good student. Encourage {him_her} to continue in this way and improve in {re_subject_low}.",
    "{name} always tries to do {his_her} best. Parents should be proud of {him_her}. However, {he_she} can still improve in {re_subject_low}.",
    "{re_subjects_high} scores are very high. Good work! However, {he_she} should focus on {re_subject_low}.",
    "{name}'s scores show {his_her} best progress. Encourage {him_her} to work on {re_subject_low}.",
    "{name} has shown great improvement this semester. However, {he_she} can still improve in {re_subject_low}.",
  ],
  good: [
    "{name} has made some improvements. However, {he_she} can still improve in {re_subject_low}.",
    "{name}'s scores in {re_subjects_high} are good, but {he_she} needs extra follow-up from parents to improve in {re_subject_low}.",
    "Please work with {him_her} on developing {his_her} skills in {re_subject_low}.",
    "{name} has the ability to do better through more effort in {re_subject_low}.",
    "{name} does well in {re_subjects_high}. Encourage {him_her} to improve in {re_subject_low}.",
    "{name}'s frequent study habit may contribute to {his_her} progress, so please let {him_her} study frequently to improve in {re_subject_low}.",
    "We encourage parents to closely follow the progress of {name} and help {him_her} improve in {re_subject_low}.",
    "Please encourage {him_her} at home to increase {his_her} effort in {re_subject_low}.",
    "{name}'s test scores are low in {re_subject_low}. Please help {him_her} prepare for the testing process.",
    "Setting a solid program for studying is recommended for {him_her} to improve in {re_subject_low}.",
  ],
  improve: [
    "{name} is making good progress in {re_subjects_high}. Therefore, guidance from home is needed to improve in {re_subject_low}.",
    "Please encourage {him_her} each day to help {him_her} improve in {re_subject_low}.",
    "{name} is intelligent but works below {his_her} potential due to a lack of frequent studying habit. Encourage {him_her} to improve in {re_subject_low}.",
    "{name} needs serious follow-up because {he_she} is working far below {his_her} level. Focus on {re_subject_low}.",
    "{name} even works below {his_her} ability but performs better in {re_subjects_high}. Encourage {him_her} to improve in {re_subject_low}.",
    "A parent-teacher conference is required to discuss {his_her} progress and how to improve in {re_subject_low}.",
    "{name} consistently needs reminders to focus on improving {his_her} score in {re_subject_low}.",
    "It is essential that {he_she} gets home support to improve {his_her} skills in {re_subject_low}.",
    "{name} is encouraged to be more responsible in completing tasks without needing regular reminders and improve in {re_subject_low}.",
    "{name} has severely low performance in {re_subject_low}. This needs constant focus from the family.",
  ],
};

// Utility function to format comments
const formatComment = (template, data) => {
  return template.replace(/{(\w+)}/g, (_, key) => data[key] || key);
};

// Function to break long text into multiple lines
const wrapText = (text, max) => {
  if (!text || text.length <= max) return text;

  let words = text.split(" ");
  let lines = [];
  let currentLine = "";

  words.forEach((word) => {
    if ((currentLine + word).length > max) {
      lines.push(currentLine.trim());
      currentLine = word;
    } else {
      currentLine += " " + word;
    }
  });

  if (currentLine) lines.push(currentLine.trim());

  return lines.join("\n");
};

// Function to generate a student comment
const generateStudentComment = (student, category, maxLineLength = 60) => {
  const commentList = comments[category] || [];

  if (!commentList.length) return "";

  const rawComment =
    commentList[Math.floor(Math.random() * commentList.length)];

  const formattedComment = formatComment(rawComment, {
    name: student.name,
    he_she: student.gender === "male" ? "he" : "she",
    him_her: student.gender === "male" ? "him" : "her",
    his_her: student.gender === "male" ? "his" : "her",
    re_subjects_high: student.highScoreSubjects.join(", "),
    re_subject_low: student.lowScoreSubjects.join(", "),
  });

  return wrapText(formattedComment, maxLineLength);
};

export default generateStudentComment;
