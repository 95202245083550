import React, { useState, useMemo, useEffect } from "react";
import { Table, Select, Card, Row, Col, Button } from "antd";
import { grade, section } from "../../../services/data";

import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
const { Option } = Select;
function StudentMidCard() {
  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [selectedGrade, setSelectedGrade] = useState("1");
  const [selectedSection, setSelectedSection] = useState("A");
  useEffect(() => {
    fetchGradeDivisions();
  }, []);
  return (
    <div id="Certificate">
      <h2>Certificate</h2>
      <Card
        title="Students report card"
        bordered={false}
        style={{ margin: "20px" }}
      >
        <div className="certificate-page">
          <Row gutter={[16, 16]} style={{ marginBottom: 16 }}>
            <Col>
              <Select
                defaultValue={selectedGrade}
                onChange={setSelectedGrade}
                style={{ width: 200 }}
              >
                {gradeDivisions.map((gra, index) => (
                  <Option key={index} value={gra.name}>
                    {gra.name}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col>
              <Select
                defaultValue={selectedSection}
                onChange={setSelectedSection}
                style={{ width: 200 }}
              >
                {section.map((sec, index) => (
                  <Option key={index} value={sec}>
                    {sec}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
        </div>
      </Card>
    </div>
  );
}

export default StudentMidCard;
