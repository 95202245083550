import React, { useState, useEffect } from "react";
import { message, Table, Select, Card, Row, Col, Form, Button } from "antd";
import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import { generateQRCode, getClassSections } from "../../../utils/helper";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { font } from "../../../utils/fonts";
import PdfModal from "../../pdf-modal/PdfModal";
import generateStudentComment from "../../../utils/reportCardComments";

const { Option } = Select;

const StudentReportCard = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [reportCardData, setReportCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [reportCardDataError, setReportCardDataError] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const { terms, fetchTerms, fetchTermsLoading } = useFetchTerms();

  const [reportCardImgUri, setReportCardImgUri] = useState("");
  const [reportCardImgUriLoading, setReportCardImgUriLoading] = useState(false);
  const [reportCardImgUriError, setReportCardImgUriError] = useState(null);

  const { get: fetchReportCardData } = useFetch("/api/roster", {
    setLoading,
    setData: setReportCardData,
    setError: setReportCardDataError,
  });

  const { get: fetchReportCardImgUri } = useFetch("/api/files/report-card", {
    setLoading: setReportCardImgUriLoading,
    setData: setReportCardImgUri,
    setError: setReportCardImgUriError,
  });

  useEffect(() => {
    fetchTerms();
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (selectedGrade && selectedSection && selectedTerm && subjectType) {
      fetchReportCardData({
        grade: selectedGrade,
        section: selectedSection,
        subjectType,
        termCode: selectedTerm,
      });
      fetchReportCardImgUri({ type: subjectType });
    }
  }, [selectedGrade, selectedSection, selectedTerm, subjectType]);

  useEffect(() => {}, [reportCardImgUri]);

  useEffect(() => {
    if (reportCardData?.data) {
      setDataSource(
        reportCardData.data.map((student) => {
          const subjectCount = student.subjects.length;
          const maxTotal = subjectCount * 100;
          const minTotal = maxTotal * 0.7;

          return {
            key: student.studentId,
            roll: student.roll,
            fullName: student.fullName,
            sex: student.gender,
            age: student.age,
            birthYear: student.birthYear,
            rank: student.rank,
            average: parseFloat(student.average),
            total: {
              value: parseFloat(student.total),
              max: maxTotal,
              min: minTotal,
            },
            absentCount: student.absentCount,
            behaviorAvg: student.behaviorAvg,
            ...Object.fromEntries(
              student.subjects.map((subj) => [
                subj.subject,
                {
                  value: parseFloat(subj.total),
                  code: subj.subject,
                  max: 100,
                  min: 70,
                },
              ])
            ),
          };
        })
      );
    }
  }, [reportCardData]);

  // Function to generate a QR Code as a PNG image

  const handlePrint = async () => {
    if (!dataSource.length) return message.warning("No report card selected");

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "pt",
      format: "a4",
    });

    // Add custom font
    doc.addFileToVFS("MeriendaOne-Regular-normal.ttf", font());
    doc.addFont(
      "MeriendaOne-Regular-normal.ttf",
      "MeriendaOne-Regular",
      "normal"
    );

    // Process each student in the data source
    for (let index = 0; index < dataSource.length; index++) {
      const student = dataSource[index];

      // Add a new page for each student after the first one
      if (index > 0) doc.addPage();

      // Add background images
      if (reportCardImgUri?.reportCardImg) {
        doc.addImage(reportCardImgUri.reportCardImg, "JPEG", 0, 0, 595, 842);
      }

      // if (reportCardImgUri?.promoted && student.average >= 60) {
      //   doc.addImage(reportCardImgUri.promoted, "PNG", 410, 480, 90, 90);
      // }

      // Generate and add QR code
      const qrCodeBase64 = await generateQRCode(student.key); // Use student key as QR data
      if (qrCodeBase64) {
        doc.addImage(qrCodeBase64, "PNG", 510, 105, 60, 60);
      }

      // Set initial position and font styles
      doc.positionY = 50;
      doc.setFont("helvetica", "bold");
      doc.setFontSize(16);
      doc.setFontSize(10);
      doc.setFont("MeriendaOne-Regular", "normal");

      // Add student details
      doc.text(`Name:`, 150, 115);
      doc.setTextColor(0, 0, 139);
      doc.text(`${student.fullName}`, 190, 115);
      doc.setTextColor(0, 0, 0);
      doc.text(
        `Roll: ${student.roll}   ID: ${student.key}   Age: ${student.age}  Sex: ${student.sex}`,
        150,
        135
      );
      doc.text(
        `${
          selectedGrade.toLowerCase().includes("grade") ? "" : "Grade: "
        }${selectedGrade}  Section: ${selectedSection}   Term: ${selectedTerm}   Year: ${
          reportCardData.year
        } E.C`,
        150,
        155
      );

      let category = "good";
      if (student.average >= 85) {
        category = "very_good";
      } else if (student.average < 60) {
        category = "improve";
      }

      // Extract subjects for high and low scores
      let re_subjects_high = Object.keys(student)
        .filter((key) => student[key]?.value >= 80)
        .map((subject) => student[subject].code)
        .slice(1);
      let re_subjects_low = Object.keys(student)
        .filter((key) => student[key]?.value < 100)
        .sort((a, b) => student[a].value - student[b].value)
        .map((subject) => student[subject].code)
        .slice(0, 2);

      if (category === "improve") {
        re_subjects_low = Object.keys(student)
          .filter((key) => student[key]?.value < 100)
          .sort((a, b) => student[a].value - student[b].value)
          .map((subject) => student[subject].code)
          .slice(0, 2);
      }

      // Generate student comment
      let studentComment = generateStudentComment(
        {
          name: student.fullName,
          gender: student.sex,
          highScoreSubjects: re_subjects_high,
          lowScoreSubjects: re_subjects_low,
        },
        category
      );

      // Print student comment
      doc.setFontSize(10);
      doc.setTextColor(0, 0, 139);
      doc.text(studentComment, 350, 210, { maxWidth: 200 });

      // Homeroom teacher's name
      doc.setTextColor(0, 0, 139);
      doc.text("Home room teacher's Name:", 30, 800);
      doc.setTextColor(40);
      doc.text(reportCardData.homeRoom + "    Sign: _________", 30, 820);

      // Table data
      let headers = ["Subject", ...terms.map((terms) => terms.name), "Average"];
      let tableData = Object.keys(student)
        .filter((key) => student[key]?.code)
        .map((subject) => [student[subject].code, student[subject].value]);
      tableData.push(["Total", student.total.value]);
      tableData.push(["Average", student.average]);
      tableData.push(["Rank", student.rank]);
      tableData.push(["Conduct", student.behaviorAvg]);
      tableData.push(["Absent", student.absentCount]);

      doc.autoTable({
        head: [headers],
        body: tableData,
        theme: "grid",
        styles: {
          halign: "left",
          cellWidth: 64,
          fontSize: 10,
          fontStyle: "bold",
          textColor: [0, 0, 0],
          fillColor: "#ffffff",
          lineColor: "#4bb20a",
          lineWidth: 0.08,
          cellPadding: 1.2,
        },
        columnStyles: {
          halign: "left",
          0: { cellWidth: 100 },
        },
        startY: 170,
        margin: { left: 20 },
        headStyles: {
          halign: "left",
          fontSize: 10,
          lineWidth: 0.05,
          lineColor: "#4bb20a",
          fillColor: [0, 102, 204],
          textColor: [255, 255, 255],
        },
      });
    }

    // Output PDF as a data URI
    const pdfDataUri = doc.output("datauristring");
    setPdfData(pdfDataUri);
    setIsModalVisible(true);
  };

  return (
    <div>
      <h2>Student's Report Card</h2>
      <Card>
        <Row gutter={[16, 8]}>
          <Col>
            <Select
              loading={fetchTermsLoading}
              placeholder="Term"
              onChange={setSelectedTerm}
              options={terms.map((t) => ({ label: t.name, value: t.code }))}
            />
          </Col>
          <Col>
            <Select
              placeholder="Grade"
              onChange={setSelectedGrade}
              options={gradeDivisions.map((g) => ({
                label: g.name,
                value: g.name,
              }))}
            />
          </Col>
          <Col>
            <Select
              placeholder="Section"
              onChange={setSelectedSection}
              options={getClassSections(selectedGrade, gradeDivisions).map(
                (s) => ({ label: s, value: s })
              )}
            />
          </Col>
          <Col>
            <Select placeholder="Subject Type" onChange={setSubjectType}>
              <Option value="academic">Academic</Option>
              <Option value="non-academic">Non-academic</Option>
            </Select>
          </Col>
        </Row>
      </Card>

      <div style={{ margin: "20px 10px" }}>
        <Button loading={loading} onClick={handlePrint}>
          Print
        </Button>
      </div>

      <PdfModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        pdfData={pdfData}
      />
    </div>
  );
};

export default StudentReportCard;
