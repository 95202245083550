import React, { useState, useEffect } from "react";
import {
  message,
  Table,
  Select,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Tag,
  Input,
  Button,
  Popconfirm,
} from "antd";
import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import usePost from "../../../hooks/usePost";
import { exportToExcel, getClassSections } from "../../../utils/helper";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import jsPDF from "jspdf";
import "jspdf-autotable";

const { Option } = Select;

const generateColumns = (assLists = {}) => {
  const cols = [
    {
      title: "Roll",
      key: "roll",
      dataIndex: "roll",
      editable: false,
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "fullName",
      editable: false,
    },
    {
      title: "Sex",
      key: "sex",
      dataIndex: "sex",
      editable: false,
    },
  ];

  Object.keys(assLists).forEach((key) => {
    if (assLists[key]?.code && assLists[key]?.max) {
      cols.push({
        title: `${assLists[key].code} (${assLists[key].max})%`,
        key: `assessment-${key}`,
        dataIndex: key,
        editable: true,
        render: (text, record) => {
          const redColor = "red";
          return (
            <h4
              style={{
                cursor: "pointer",
                minWidth: "20px",
                minHeight: "20px",
                padding: 5,
                display: "block",
                margin: 0,
                color:
                  record[key]?.value < record[key]?.min ? redColor : "black",
              }}
            >
              {record[key]?.value || ""}
            </h4>
          );
        },
      });
    }
  });

  return cols;
};

// match the ass of this system to hamza

const StudentTranscript = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);

  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();

  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");

  const [subjectType, setSubjectType] = useState("");
  const [subjectFilteredWithType, setSubjectFilteredWithType] = useState([]);

  const { terms, fetchTermsLoading, fetchTermsError, fetchTerms } =
    useFetchTerms();

  const [subjects, setSubjects] = useState([]);
  const [subjLoading, setSubjLoading] = useState(false);
  const [subjFetchError, setSubjFetchError] = useState(null);
  const [marks, setMarks] = useState([]);
  const [markLoading, setMarkLoading] = useState(false);

  const [buildRosterRes, setBuildRosterRes] = useState([]);
  const [buildRosterLoading, setBuildRosterLoading] = useState(false);
  const [buildRosterError, setBuildRosterError] = useState(null);

  const { get: fetchSubj } = useFetch("/api/subject", {
    setLoading: setSubjLoading,
    setError: setSubjFetchError,
    setData: setSubjects,
  });

  const { get: fetchMark } = useFetch("/api/roster/adminRoster", {
    setLoading: setMarkLoading,
    setError: () => {},
    setData: setMarks,
  });

  const { post: buildRoster } = usePost("/api/roster/build", {
    setLoading: setBuildRosterLoading,
    setError: setBuildRosterError,
    setData: setBuildRosterRes,
  });

  const getSelectedRoster = () => {
    setMarks([]);
    fetchMark({
      grade: selectedGrade,
      section: selectedSection,
      subject: selectedSubject,
      subjectType: subjectType,
      termCode: selectedTerm,
    });
  };

  useEffect(() => {
    fetchTerms();
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (
      !selectedGrade ||
      !selectedSection ||
      !selectedSubject ||
      !selectedTerm ||
      !subjectType
    )
      return;
    getSelectedRoster();
  }, [
    selectedGrade,
    selectedSection,
    selectedSubject,
    selectedTerm,
    subjectType,
  ]);

  useEffect(() => {
    setSubjects(null);
    if (selectedGrade) fetchSubj({ grade: selectedGrade });
  }, [selectedGrade]);

  useEffect(() => {
    if (!subjectType) return;
    form?.setFieldsValue({ subject: null });
    setSubjectFilteredWithType(
      subjects?.filter((subj) => subj.type === subjectType)
    );
  }, [subjectType]);

  useEffect(() => {
    if (buildRosterRes?.status)
      message[buildRosterRes?.status || "success"](
        buildRosterRes?.message || "success"
      );
  }, [buildRosterRes]);

  useEffect(() => {
    if (buildRosterError?.status)
      message[buildRosterError?.status || "error"](
        buildRosterError?.message || "error"
      );
  }, [buildRosterError]);

  useEffect(() => {
    if (marks.length) {
      const assData = marks.map((mark, index) => {
        const data = {
          key: mark[0]?.studentId || index,
          id: mark[0]?.studentId,
          roll: mark[0]?.roll,
          fullName: mark[0]?.fullName,
          sex: mark[0]?.sex,
        };

        mark.slice(1).forEach((ass) => {
          data[`${ass.assessmentCode}`] = {
            value: ass.assessmentValue,
            code: ass.assessmentCode,
            max: ass.assessmentMaxValue,
            min: ass.assessmentMinValue,
          };
        });

        return data;
      });

      setDataSource(assData);
    }
  }, [marks]);

  const handlePrint = () => {
    if (
      !selectedGrade ||
      !selectedSection ||
      !selectedSubject ||
      !selectedTerm ||
      !subjectType
    )
      return message.warning("no roster selected");
    const doc = new jsPDF({ orientation: "portrait", unit: "pt" });

    // Generate columns for the table
    const columns = generateColumns(dataSource?.[0] || {}).map((col) => {
      console.log(col);

      return {
        header: col.title,
        dataKey: col.dataIndex,
      };
    });

    // Map dataSource rows into a format compatible with the jsPDF table
    const rows = dataSource.map((row) => {
      const rowData = {};
      columns.forEach((col) => {
        const value = row[col.dataKey]; // Access the correct value by key
        rowData[col.dataKey] =
          typeof value === "object" && value !== null
            ? value.value
            : value || "";
      });
      return rowData;
    });
    doc.setFontSize(10);
    doc.setTextColor(40);
    doc.setFont("", "", "bold");

    doc.text("Student's mark list", 40, 30);

    doc.text(
      " " +
        selectedGrade +
        "          Section   " +
        selectedSection +
        "          Term   " +
        selectedTerm,
      40,
      50
    );

    // Add table to the PDF
    doc.autoTable({
      startY: 70,
      head: [columns.map((col) => col.header)], // Table headers
      body: rows.map((row) => columns.map((col) => row[col.dataKey] || "")), // Table rows
    });

    // Save the PDF
    doc.save(
      `${
        selectedGrade + " Section " + selectedSection + " Term " + selectedTerm
      } mark list.pdf`
    );
  };

  const handleToExcel = () => {
    if (
      !selectedGrade ||
      !selectedSection ||
      !selectedSubject ||
      !selectedTerm ||
      !subjectType
    )
      return message.warning("No mark list selected");

    if (!dataSource?.length) return;

    // Process data similar to handlePrint
    const processedData = dataSource.map((row) => {
      const newRow = {};
      Object.keys(row).forEach((key) => {
        const value = row[key];
        newRow[key] =
          typeof value === "object" && value !== null
            ? value.value
            : value || "";
      });
      return newRow;
    });

    exportToExcel(
      processedData,
      `${
        selectedGrade + " Section " + selectedSection + " Term " + selectedTerm
      } mark list.xlsx`
    );
  };

  return (
    <div id="Roster">
      <h2>Mark List</h2>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Popconfirm
          title="This will take sometime"
          onConfirm={() => buildRoster({})}
        >
          <Button
            loading={buildRosterLoading}
            type="primary"
            style={{ margin: "10px 0" }}
          >
            Build Mark List
          </Button>
        </Popconfirm>
      </div>
      <Card bordered={false} style={{ margin: "10px 0" }}>
        <Row gutter={[16, 8]}>
          <Col>
            <Select
              placeholder="Term"
              defaultValue={
                terms.find((term) => term.status === "current")?.name
              }
              onChange={(term) => setSelectedTerm(term)}
              options={terms.map((term) => ({
                label: term.name,
                value: term.code,
                key: term.code,
              }))}
              style={{ marginBottom: 10 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Grade"
              style={{ marginBottom: 20 }}
              name="grade"
              onChange={(val) => {
                setSelectedGrade(val);
              }}
              options={gradeDivisions?.map((gra, index) => ({
                key: index,
                value: gra.name,
                label: gra.name,
              }))}
            />
          </Col>
          <Col>
            <Select
              placeholder="Section"
              options={getClassSections(selectedGrade, gradeDivisions).map(
                (val) => ({
                  label: val,
                  value: val,
                })
              )}
              onChange={(val) => setSelectedSection(val)}
              style={{ marginBottom: 10 }}
            />
          </Col>
          <Col>
            <Select
              placeholder="Subject Type"
              onChange={(value) => setSubjectType(value)}
              style={{ marginBottom: 10 }}
            >
              <Option value="academic">Academic</Option>
              <Option value="non-academic">Non-academic</Option>
            </Select>
          </Col>
          <Col>
            <Select
              placeholder="Subject"
              loading={subjLoading}
              onChange={(subj) => setSelectedSubject(subj)}
              options={subjectFilteredWithType.map((subject) => ({
                key: subject.id,
                label: subject.name,
                value: subject.name,
              }))}
            ></Select>
          </Col>
        </Row>
      </Card>

      <Table
        rowClassName={() => "editable-row"}
        bordered
        scroll={{ x: 1300 }}
        pagination={{ pageSize: 55 }}
        dataSource={dataSource}
        columns={generateColumns(dataSource?.[0])}
        loading={markLoading}
      />
      <div style={{ width: "100%", display: "flex", justifyContent: "start" }}>
        <Button
          type="primary"
          onClick={handlePrint}
          style={{ margin: "10px 0" }}
        >
          Print
        </Button>
        <Button
          type="primary"
          onClick={handleToExcel}
          style={{ margin: "10px 0", marginLeft: 10 }}
        >
          To excel
        </Button>
      </div>
    </div>
  );
};

export default StudentTranscript;
