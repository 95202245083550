import React, { useState, useEffect } from "react";
import {
  message,
  Table,
  Select,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Tag,
  Input,
  Button,
} from "antd";
import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import "./Assessments.css";
import usePost from "../../../hooks/usePost";

const generateColumns = (assLists = {}, handleCellClick) => {
  const cols = [
    {
      title: "Roll",
      key: "roll",
      dataIndex: "roll",
      editable: false,
    },
    {
      title: "Name",
      key: "name",
      dataIndex: "fullName",
      editable: false,
    },
    {
      title: "Sex",
      key: "sex",
      dataIndex: "sex",
      editable: false,
    },
  ];

  // Add dynamic columns for assessments
  Object.keys(assLists).forEach((key) => {
    if (assLists[key]?.code && assLists[key]?.max) {
      cols.push({
        title: `${assLists[key].code} (${assLists[key].max})%`,
        key: `assessment-${key}`, // Ensure a unique key for each column
        dataIndex: key,
        editable: key !== "Total(70%)" && key !== "Total(100%)", // Disable editing for 70% and 100% columns
        render: (text, record) => {
          const redColor = "red";
          return (
            <h4
              style={{
                cursor:
                  key !== "Total(70%)" && key !== "Total(100%)"
                    ? "pointer"
                    : "default",
                minWidth: "20px",
                minHeight: "20px",
                padding: 5,
                display: "block",
                margin: 0,
                color:
                  record[key]?.value < record[key]?.min ? redColor : "black",
              }}
              onClick={() =>
                key !== "Total(70%)" &&
                key !== "Total(100%)" &&
                handleCellClick(record, key)
              }
            >
              {record[key]?.value || ""}
            </h4>
          );
        },
      });
    }
  });

  return cols;
};

const MarkList = () => {
  const [form] = Form.useForm(); // Create form instance
  const [dataSource, setDataSource] = useState([]);
  const [isMarkListChanged, setIsMarkListChanged] = useState(false);
  const [currentUnchangedValue, setCurrentUnchangedValue] = useState(undefined);

  const { terms, fetchTermsLoading, fetchTermsError, fetchTerms } =
    useFetchTerms();

  const [addAssLoading, setAddAssLoading] = useState(false);
  const [addAssError, setAddAssError] = useState(null);
  const [addAssRes, setAddAssRes] = useState(null);

  const [currentTerm, setCurrentTerm] = useState(null);
  const [selectedTask, setSelectedTask] = useState("");
  const [myTasks, setMyTasks] = useState([]);
  const [myTasksLoading, setMyTasksLoading] = useState([]);
  const [selectedRowData, setSelectedRowData] = useState(null);

  // Fetch marks
  const [marks, setMarks] = useState([]);
  const [markLoading, setMarkLoading] = useState(false);

  const { get: fetchMark } = useFetch("/api/assessment/markList", {
    setLoading: setMarkLoading,
    setError: () => {},
    setData: setMarks,
  });

  const { get: fetchMyTask } = useFetch("/api/assessment/tasks", {
    setLoading: setMyTasksLoading,
    setError: () => {},
    setData: setMyTasks,
  });

  const { post: addAssValue, reset: resetAddAss } = usePost(
    "/api/assessment/addAss",
    {
      setLoading: setAddAssLoading,
      setError: setAddAssError,
      setData: setAddAssRes,
    }
  );

  const getSelectedMarkList = () => {
    fetchMark({
      grade: selectedTask.grade,
      section: selectedTask.section,
      subject: selectedTask.subject,
      subjectType: selectedTask.subjectType,
    });
  };

  const handleTaskChange = (val) => {
    setSelectedTask(myTasks.find((task) => task.id === val));
  };

  useEffect(() => {
    fetchMyTask();
    fetchTerms();
  }, []);

  useEffect(() => {
    if (addAssRes?.message)
      message[addAssRes?.status || "success"](addAssRes?.message || "success");
    resetAddAss();
    if (addAssRes?.status === "success") {
      if (selectedRowData?.record) handleNext(selectedRowData.record);
    }
  }, [addAssRes]);

  useEffect(() => {
    if (addAssError?.message)
      message[addAssError?.status || "error"](addAssError?.message || "error");

    resetAddAss();
  }, [addAssError]);

  useEffect(() => {
    if (selectedTask?.grade && currentTerm?.name) {
      getSelectedMarkList();
    } else if (selectedTask) {
      message.error("System error. Please reload the page and try again.");
    }
  }, [selectedTask]);

  useEffect(() => {
    if (marks.length) {
      const assData = marks.map((mark, index) => {
        const data = {
          key: mark[0]?.studentId || index,
          id: mark[0]?.studentId,
          roll: mark[0]?.roll,
          fullName: mark[0]?.fullName,
          sex: mark[0]?.sex,
        };

        let total70 = 0;
        mark.slice(1, -1).forEach((ass) => {
          total70 += ass.assessmentValue;
          data[`${ass.assessmentCode}`] = {
            value: ass.assessmentValue,
            code: ass.assessmentCode,
            max: ass.assessmentMaxValue,
            min: ass.assessmentMinValue,
          };
        });

        const lastAssessment = mark[mark.length - 1];
        const total100 = total70 + lastAssessment.assessmentValue;

        data["Total(70%)"] = {
          value: total70,
          code: "Total",
          max: 70,
          min: 0,
        };

        data[`${lastAssessment.assessmentCode}`] = {
          value: lastAssessment.assessmentValue,
          code: lastAssessment.assessmentCode,
          max: lastAssessment.assessmentMaxValue,
          min: lastAssessment.assessmentMinValue,
        };

        data["Total(100%)"] = {
          value: total100,
          code: "Total",
          max: 100,
          min: 0,
        };

        return data;
      });

      setDataSource(assData);
    }
  }, [marks]);

  useEffect(() => {
    if (terms) setCurrentTerm(terms.find((term) => term.status === "current"));
  }, [terms]);

  const saveOriginalValue = (record, key) => {
    const copiedRecord = structuredClone(record);
    setCurrentUnchangedValue({ copiedRecord, key });
  };

  const handleCellClick = (record, key) => {
    saveOriginalValue(record, key);
    setSelectedRowData({ record, key });
    setIsMarkListChanged(false);
  };

  const handleModalClose = () => {
    form.resetFields();
    setSelectedRowData(null);
    if (isMarkListChanged) {
      getSelectedMarkList();
    }
  };

  const updateAssFieldValue = (record) => {
    form.setFieldsValue({
      assValue: record[selectedRowData.key].value,
    });
  };

  useEffect(() => {
    if (selectedRowData?.record && selectedRowData?.key)
      updateAssFieldValue(selectedRowData.record);
  }, [selectedRowData]);

  const handlePrev = (record) => {
    const key = selectedRowData.key;

    const currentIndex = dataSource.findIndex((item) => item.id === record.id);
    if (currentIndex === -1) return message.warning("No previous student");

    if (currentIndex > 0) {
      const prevRecord = dataSource[currentIndex - 1];
      setSelectedRowData({ record: prevRecord, key });
      saveOriginalValue(prevRecord, key);
      updateAssFieldValue(prevRecord);
    } else {
      message.warning("No previous student");
      return null;
    }
  };

  const handleAddAss = async (record) => {
    console.log(
      currentUnchangedValue.copiedRecord[currentUnchangedValue.key].value,
      record[currentUnchangedValue.key].value
    );

    if (
      parseInt(
        currentUnchangedValue.copiedRecord[currentUnchangedValue.key].value
      ) !== parseInt(record[currentUnchangedValue.key].value)
    ) {
      console.log("different");
      addAssValue({
        record,
        key: currentUnchangedValue.key,
        detail: selectedTask,
      });
    } else {
      handleNext(record);
    }
  };

  const handleNext = (record) => {
    const key = selectedRowData.key;

    const currentIndex = dataSource.findIndex((item) => {
      return item.id === record.id;
    });

    if (currentIndex === -1) return message.warning("No next student");

    if (currentIndex < dataSource.length - 1) {
      const nextRecord = dataSource[currentIndex + 1];
      setSelectedRowData({ record: nextRecord, key });
      saveOriginalValue(nextRecord, key);
      updateAssFieldValue(nextRecord);
    } else {
      message.warning("No next student");
      return null;
    }
  };

  return (
    <div id="MarkList">
      <h2>Mark List</h2>
      <Card bordered={false}>
        <Row gutter={[16, 8]}>
          <Col>
            <Select
              loading={myTasksLoading}
              placeholder="Select mark list"
              style={{ width: 250, marginBottom: 10 }}
              onChange={handleTaskChange}
              options={myTasks.map((task) => ({
                label: `${task.grade} ${task.section} ${task.subject} ${task.subjectType}`,
                value: task.id,
              }))}
            />
          </Col>
          <Col>
            <Tag style={{ padding: "5px", fontWeight: 700 }}>
              {currentTerm?.name}
            </Tag>
          </Col>
        </Row>
      </Card>
      <Table
        rowClassName={() => "editable-row"}
        bordered
        scroll={{ x: 1300 }}
        pagination={{ pageSize: 50 }}
        dataSource={dataSource}
        columns={generateColumns(dataSource?.[0], handleCellClick)}
        loading={markLoading}
      />

      <Modal
        open={selectedRowData !== null}
        onCancel={handleModalClose}
        footer={null}
        width={800}
      >
        <Card title="Set Values">
          {selectedRowData && (
            <>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "10px",
                  marginBottom: "20px",
                  paddingBottom: "10px",
                  borderBottom: "1px solid #d9d9d9",
                }}
              >
                <div style={{ textAlign: "left" }}>
                  <h4
                    style={{ margin: 0, fontWeight: "normal", color: "#333" }}
                  >
                    Roll:{" "}
                    <span
                      style={{
                        fontWeight: 600,
                        color: "#1890ff",
                        fontSize: 30,
                      }}
                    >
                      {selectedRowData.record.roll}
                    </span>
                  </h4>
                </div>
                <div style={{ textAlign: "left" }}>
                  <h4
                    style={{ margin: 0, fontWeight: "normal", color: "#333" }}
                  >
                    ID:{" "}
                    <span style={{ fontWeight: 600, color: "#1890ff" }}>
                      {selectedRowData.record.id}
                    </span>
                  </h4>
                </div>
                <div style={{ textAlign: "left" }}>
                  <h4
                    style={{ margin: 0, fontWeight: "normal", color: "#333" }}
                  >
                    Name:{" "}
                    <span style={{ fontWeight: 600, color: "#1890ff" }}>
                      {selectedRowData.record.fullName}
                    </span>
                  </h4>
                </div>
                <div style={{ textAlign: "left" }}>
                  <h4
                    style={{ margin: 0, fontWeight: "normal", color: "#333" }}
                  >
                    Sex:{" "}
                    <span style={{ fontWeight: 600, color: "#1890ff" }}>
                      {selectedRowData.record.sex}
                    </span>
                  </h4>
                </div>
                <div style={{ textAlign: "left" }}>
                  <h4
                    style={{ margin: 0, fontWeight: "normal", color: "#333" }}
                  >
                    Ass:{" "}
                    <span style={{ fontWeight: 600, color: "#1890ff" }}>
                      {selectedRowData.key}
                    </span>{" "}
                    Max:{" "}
                    <span style={{ fontWeight: 600, color: "#1890ff" }}>
                      {selectedRowData.record[selectedRowData.key].max}
                    </span>
                  </h4>
                </div>
              </div>

              <h4
                style={{
                  marginBottom: "15px",
                  fontSize: "16px",
                  color: "#555",
                  fontWeight: "bold",
                }}
              >
                Value:
              </h4>

              <Form form={form}>
                <Form.Item
                  name="assValue"
                  rules={[
                    {
                      validator: (_, value) => {
                        const maxValue =
                          selectedRowData.record[selectedRowData.key].max;
                        if (value <= maxValue) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          `Value cannot exceed ${maxValue}`
                        );
                      },
                    },
                  ]}
                >
                  <Input
                    style={{ width: 100 }}
                    type="number"
                    onChange={(e) => {
                      const inputValue = parseInt(e.target.value, 10) || 0;
                      if (
                        inputValue <=
                        selectedRowData.record[selectedRowData.key].max
                      ) {
                        selectedRowData.record[selectedRowData.key].value =
                          inputValue;
                      }
                    }}
                  />
                </Form.Item>
                <Button
                  loading={addAssLoading}
                  type="primary"
                  onClick={() => handlePrev(selectedRowData.record)}
                >
                  Prev
                </Button>
                <Button
                  loading={addAssLoading}
                  type="primary"
                  style={{ marginLeft: 10 }}
                  onClick={() => handleAddAss(selectedRowData.record)}
                >
                  Next
                </Button>
              </Form>
            </>
          )}
        </Card>
      </Modal>
    </div>
  );
};

export default MarkList;
