import React from "react";
import { Modal, Button, Space, Card } from "antd";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { DownloadOutlined, PrinterOutlined } from "@ant-design/icons";
const PdfModal = ({ visible, onClose, pdfData }) => {
  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = pdfData;
    link.download = "ReportCard.pdf";
    link.click();
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");
    if (printWindow) {
      printWindow.document.write(
        `<iframe src="${pdfData}" frameborder="0" style="border:0; top:0; left:0; bottom:0; right:0; width:100%; height:100%;" allowfullscreen></iframe>`
      );
      printWindow.document.close();
      printWindow.focus();
    }
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
    >
      <Space
        style={{ display: "flex", justifyContent: "start", marginBottom: 16 }}
      >
        <Button icon={<DownloadOutlined />} onClick={handleDownload}>
          Download
        </Button>
        <Button icon={<PrinterOutlined />} onClick={handlePrint}>
          Print
        </Button>
      </Space>
      <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
          <Viewer fileUrl={pdfData} />
        </Worker>
      </div>
    </Modal>
  );
};

export default PdfModal;
