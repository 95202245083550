import React, { useState, useEffect } from "react";
import { message, Table, Select, Card, Row, Col, Form, Button } from "antd";
import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import { exportToExcel, getClassSections } from "../../../utils/helper";
import useFetchGradeDivisions from "../../../hooks/useFetchGradeDivisions";
import jsPDF from "jspdf";
import "jspdf-autotable";

const { Option } = Select;

const generateColumns = (assessments = {}, roster) => {
  const columns = [
    { title: "Roll", dataIndex: "roll", key: "roll" },
    { title: "Name", dataIndex: "fullName", key: "fullName" },
    { title: "Sex", dataIndex: "sex", key: "sex" },
  ];

  Object.keys(assessments).forEach((key, index) => {
    if (assessments[key]?.code && assessments[key]?.max) {
      columns.push({
        title: `${assessments[key].code} (${assessments[key].max})%`,
        dataIndex: key,
        key: `${key}-${index}`,
        render: (text, record) => (
          <h4
            style={{
              color: record[key]?.value < record[key]?.min ? "red" : "black",
            }}
          >
            {record[key]?.value || ""}
          </h4>
        ),
      });
    }
  });

  columns.push(
    {
      title: `Total (${roster?.data?.[0]?.subjects.length * 100}%)`,
      dataIndex: "total",
      key: "total",
      render: (text, record) => (
        <h4
          style={{
            color: record.total.value < record.total.min ? "red" : "black",
          }}
        >
          {record.total.value}
        </h4>
      ),
    },
    {
      title: "Average",
      dataIndex: "average",
      key: "average",
      render: (text, record) => (
        <h4 style={{ color: record.average < 70 ? "red" : "black" }}>
          {record.average}
        </h4>
      ),
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Absent",
      dataIndex: "absentCount",
      key: "absentCount",
      render: (text) => <h4>{text}</h4>,
    },
    {
      title: "Behavior",
      dataIndex: "behaviorAvg",
      key: "behaviorAvg",
      render: (text) => <h4>{text}</h4>,
    }
  );

  return columns;
};

const StudentRoster = () => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedTerm, setSelectedTerm] = useState("");
  const [subjectType, setSubjectType] = useState("");
  const [roster, setRoster] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rosterError, setRosterError] = useState(null);

  const { gradeDivisions, fetchGradeDivisions } = useFetchGradeDivisions();
  const { terms, fetchTerms } = useFetchTerms();
  const { get: fetchRoster } = useFetch("/api/roster", {
    setLoading,
    setData: setRoster,
    setError: setRosterError,
  });

  useEffect(() => {
    fetchTerms();
    fetchGradeDivisions();
  }, []);

  useEffect(() => {
    if (selectedGrade && selectedSection && selectedTerm && subjectType) {
      fetchRoster({
        grade: selectedGrade,
        section: selectedSection,
        subjectType,
        termCode: selectedTerm,
      });
    }
  }, [selectedGrade, selectedSection, selectedTerm, subjectType]);

  useEffect(() => {
    if (roster?.data) {
      setDataSource(
        roster.data.map((student) => {
          const subjectCount = student.subjects.length;
          const maxTotal = subjectCount * 100;
          const minTotal = maxTotal * 0.7;

          return {
            key: student.studentId,
            roll: student.roll,
            fullName: student.fullName,
            sex: student.gender,
            rank: student.rank,
            average: parseFloat(student.average),
            total: {
              value: parseFloat(student.total),
              max: maxTotal,
              min: minTotal,
            },
            absentCount: student.absentCount,
            behaviorAvg: student.behaviorAvg,
            ...Object.fromEntries(
              student.subjects.map((subj) => [
                subj.subject,
                {
                  value: parseFloat(subj.total),
                  code: subj.subject,
                  max: 100,
                  min: 70,
                },
              ])
            ),
          };
        })
      );
    }
  }, [roster]);

  const handlePrint = () => {
    if (!dataSource.length) return message.warning("No roster selected");

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "pt",
      format: "a4",
    });

    doc.setFont("helvetica", "bold");
    doc.setFontSize(12);
    doc.text(
      `Student's ${
        subjectType.charAt(0).toUpperCase() + subjectType.slice(1).toLowerCase()
      } Roster`,
      40,
      25
    );
    doc.setFontSize(10);
    doc.text(
      `${
        selectedGrade.toLowerCase().includes("grade") ? "" : "Grade: "
      } ${selectedGrade}`,
      40,
      45
    );
    doc.text(
      `Section: ${selectedSection}`,
      selectedGrade.toLowerCase().includes("grade") ? 100 : 150,
      45
    );

    // ✅ Extract subjects dynamically
    const firstRow = dataSource[0] || {};
    const subjects = Object.keys(firstRow).filter(
      (key) => typeof firstRow[key] === "object" && firstRow[key]?.code
    );

    // ✅ Extract Column Headers
    const columns = [
      { title: "Roll", dataIndex: "roll" },
      { title: "Name", dataIndex: "fullName" },
      { title: "Sex", dataIndex: "sex" },
      ...subjects.map((sub) => ({
        title: `${firstRow[sub].code} (${firstRow[sub].max}%)`,
        dataIndex: sub,
      })),
      { title: `Total (${subjects.length * 100}%)`, dataIndex: "total" },
      { title: "Average", dataIndex: "average" },
      { title: "Rank", dataIndex: "rank" },
      { title: "Absent", dataIndex: "absentCount" },
      { title: "Behavior", dataIndex: "behaviorAvg" },
    ];

    // ✅ Prepare Table Data with Coloring Logic
    const tableData = dataSource.map((row) => {
      return columns.map(({ dataIndex }) => {
        if (
          [
            "roll",
            "fullName",
            "sex",
            "rank",
            "absentCount",
            "behaviorAvg",
          ].includes(dataIndex)
        ) {
          return row[dataIndex] ?? "";
        }

        if (dataIndex === "total") {
          return {
            value: row.total.value,
            isLow: row.total.value < row.total.min, // ✅ Check Total against Min
          };
        }

        if (dataIndex === "average") {
          return {
            value: row.average,
            isLow: row.average < 70, // ✅ Check Average against 70
          };
        }

        // ✅ Handle Subject Scores
        if (row[dataIndex]) {
          const score = row[dataIndex].value || 0;
          const maxScore = row[dataIndex].max || 100;
          return {
            value: score,
            isLow: score < maxScore * 0.7, // ✅ Less than 70% of max?
          };
        }

        return { value: "", isLow: false };
      });
    });

    // ✅ Generate Table (FIXED)
    doc.autoTable({
      head: [columns.map((col) => col.title)],
      body: tableData.map((row) =>
        row.map((cell) => (typeof cell === "object" ? cell.value : cell))
      ),
      startY: 60,
      styles: { fontSize: 9, cellPadding: 3 }, // ✅ Reduce font size slightly
      headStyles: { fontSize: 8, halign: "center", cellPadding: 2 }, // ✅ Smaller header font, centered
      columnStyles: {
        0: { cellWidth: 30 }, // Roll
        1: { cellWidth: 80 }, // Name
        2: { cellWidth: 30 }, // Sex
        [columns.length - 5]: { cellWidth: 50 }, // Total
        [columns.length - 4]: { cellWidth: 50 }, // Average
        [columns.length - 3]: { cellWidth: 40 }, // Rank
        [columns.length - 2]: { cellWidth: 50 }, // Absent
        [columns.length - 1]: { cellWidth: 50 }, // Behavior
      },
      theme: "grid",
      margin: { left: 20, right: 20 },
      didParseCell: (data) => {
        if (data.section === "body") {
          const cellData = tableData[data.row.index][data.column.index];

          if (typeof cellData === "object" && cellData.isLow) {
            data.cell.styles.textColor = [255, 0, 0]; // ✅ Red if below threshold
          }
        }
      },
      didDrawPage: (data) => {
        doc.setFont("helvetica", "bold");
        doc.setFontSize(9);
        doc.text(`Home Room Name: `, 40, doc.internal.pageSize.height - 25);
        doc.setFont("helvetica", "normal");
        doc.text(
          `${roster?.homeRoom || "__________________________"}`,
          130,
          doc.internal.pageSize.height - 25
        );
      },
    });

    doc.save(`Roster_${selectedGrade}_${selectedSection}_${selectedTerm}.pdf`);
  };

  const handleToExcel = () => {
    if (!dataSource.length) return message.warning("No roster selected");

    const formattedData = dataSource.map((row) => {
      // Extract subject columns first (excluding predefined keys and 'key')
      const subjects = Object.fromEntries(
        Object.keys(row)
          .filter(
            (col) =>
              ![
                "key",
                "roll",
                "fullName",
                "sex",
                "total",
                "average",
                "rank",
                "absentCount", // Ensure Absent is included
                "behaviorAvg", // Ensure Behavior is included
              ].includes(col)
          )
          .map((subject) => [subject, row[subject]?.value || ""])
      );

      // Return the final object with Total, Average, Rank, Absent, and Behavior at the end
      return {
        Roll: row.roll,
        Name: row.fullName,
        Sex: row.sex,
        ...subjects, // Add subject columns here
        Total: row.total?.value || "", // Move Total to the end
        Average: row.average || "", // Move Average to the end
        Rank: row.rank || "", // Move Rank to the end
        Absent: row.absentCount || "", // ✅ Ensure Absent is included
        Behavior: row.behaviorAvg || "", // ✅ Ensure Behavior is included
      };
    });

    // Export the formatted data to Excel
    exportToExcel(
      formattedData,
      `Roster_${selectedGrade}_${selectedSection}_${selectedTerm}.xlsx`
    );
  };

  return (
    <div>
      <h2>Student's Roster</h2>
      <Card>
        <Row gutter={[16, 8]}>
          <Col>
            <Select
              placeholder="Term"
              onChange={setSelectedTerm}
              options={terms.map((t) => ({ label: t.name, value: t.code }))}
            />
          </Col>
          <Col>
            <Select
              placeholder="Grade"
              onChange={setSelectedGrade}
              options={gradeDivisions.map((g) => ({
                label: g.name,
                value: g.name,
              }))}
            />
          </Col>
          <Col>
            <Select
              placeholder="Section"
              onChange={setSelectedSection}
              options={getClassSections(selectedGrade, gradeDivisions).map(
                (s) => ({ label: s, value: s })
              )}
            />
          </Col>
          <Col>
            <Select placeholder="Subject Type" onChange={setSubjectType}>
              <Option value="academic">Academic</Option>
              <Option value="non-academic">Non-academic</Option>
            </Select>
          </Col>
        </Row>
      </Card>
      <Table
        dataSource={dataSource}
        columns={generateColumns(dataSource[0] || {}, roster)}
        loading={loading}
        pagination={{ pageSize: 50 }}
        scroll={{ x: 1000 }}
      />
      <div style={{ margin: "20px 10px" }}>
        <Button onClick={handlePrint}>Print</Button>
        <Button onClick={handleToExcel} style={{ marginLeft: 10 }}>
          Export to Excel
        </Button>
      </div>
    </div>
  );
};

export default StudentRoster;
