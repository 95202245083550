import React, { useState, useEffect } from "react";
import {
  message,
  Table,
  Select,
  Card,
  Row,
  Col,
  Form,
  Modal,
  Input,
  Button,
} from "antd";
import useFetch from "../../../hooks/useFetch";
import useFetchTerms from "../../../hooks/useFetchTerms";
import usePost from "../../../hooks/usePost";

const generateColumns = (handleCellClick) => [
  { title: "Roll", key: "roll", dataIndex: "roll", editable: false },
  { title: "Name", key: "name", dataIndex: "fullName", editable: false },
  {
    title: "Type",
    key: "type",
    dataIndex: "type",
    render: (text) => (text === "attendance" ? "Attendance" : "Behavior"),
  },
  {
    title: "Details",
    key: "details",
    render: (_, record) => (
      <Button onClick={() => handleCellClick(record)}>Edit</Button>
    ),
  },
];

const StudentAttendanceAndBehavior = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const [dataLoading, setDataLoading] = useState(false);
  const [dataError, setDataError] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);
  const { terms, fetchTerms } = useFetchTerms();
  const [currentTerm, setCurrentTerm] = useState(null);
  const [selectedTask, setSelectedTask] = useState("");
  const [myTasks, setMyTasks] = useState([]);
  const [myTasksLoading, setMyTasksLoading] = useState(false);
  const [myTaskError, setMyTaskError] = useState(false);

  const [updateLoading, setUpdateLoading] = useState(false);
  const [updateResp, setUpdateResp] = useState(null);
  const [updateError, setUpdateError] = useState(null);

  const { get: fetchData } = useFetch("/api/attendance-behavior/data", {
    setLoading: setDataLoading,
    setData: setDataSource,
    setError: setDataError,
  });

  const { get: fetchTasks } = useFetch("/api/attendance-behavior/tasks", {
    setLoading: setMyTasksLoading,
    setData: setMyTasks,
    setError: setMyTaskError,
  });

  const { post: updateRecord } = usePost("/api/attendance-behavior/update", {
    setData: setUpdateResp,
    setLoading: setUpdateLoading,
    setError: setUpdateError,
  });

  const getSelectedData = () => {
    fetchData({
      grade: selectedTask.grade,
      section: selectedTask.section,
    });
  };

  const handleTaskChange = (val) => {
    setSelectedTask(myTasks.find((task) => task.id === val));
  };

  useEffect(() => {
    fetchTasks();
    fetchTerms();
  }, []);

  useEffect(() => {
    if (selectedTask?.grade && currentTerm?.name) {
      getSelectedData();
    }
  }, [selectedTask]);

  useEffect(() => {
    if (terms) setCurrentTerm(terms.find((term) => term.status === "current"));
  }, [terms]);

  const handleCellClick = (record) => {
    setSelectedRowData(record);
    form.setFieldsValue({ data: JSON.stringify(record.data, null, 2) });
  };

  const handleModalClose = () => {
    form.resetFields();
    setSelectedRowData(null);
  };

  const handleSave = async () => {
    try {
      const updatedData = JSON.parse(form.getFieldValue("data"));
      await updateRecord({
        id: selectedRowData.id,
        type: selectedRowData.type,
        data: updatedData,
      });
      message.success("Updated successfully!");
      handleModalClose();
      getSelectedData();
    } catch (error) {
      message.error("Invalid data format");
    }
  };

  return (
    <Card title="Student Attendance & Behavior">
      <Row gutter={[16, 8]} style={{ margin: "10px 0" }}>
        <Col span={8}>
          <Select
            showSearch
            placeholder="Select Task"
            optionFilterProp="children"
            onChange={handleTaskChange}
            loading={myTasksLoading}
          >
            {myTasks.map((task) => (
              <Select.Option key={task.id} value={task.id}>
                {`${task.grade} ${task.section}`}
              </Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={8}>{currentTerm?.name}</Col>
      </Row>

      <Table
        bordered
        pagination={{ pageSize: 50 }}
        dataSource={dataSource}
        columns={generateColumns(handleCellClick)}
        loading={dataLoading}
      />

      {selectedRowData && (
        <Modal
          title={`Edit ${selectedRowData.type}`}
          open={true}
          onCancel={handleModalClose}
          footer={[
            <Button key="cancel" onClick={handleModalClose}>
              Cancel
            </Button>,
            <Button key="save" type="primary" onClick={handleSave}>
              Save
            </Button>,
          ]}
        >
          <p>
            Roll: {selectedRowData.roll} | ID: {selectedRowData.id} | Name:{" "}
            {selectedRowData.fullName}
          </p>
          <Form form={form}>
            <Form.Item
              name="data"
              rules={[
                {
                  required: true,
                  message: "Please enter a valid JSON array!",
                },
              ]}
            >
              <Input.TextArea rows={6} />
            </Form.Item>
          </Form>
        </Modal>
      )}
    </Card>
  );
};

export default StudentAttendanceAndBehavior;
